import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/achievement',
    name: 'achievement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "achievement" */ '../views/achievement.vue')
  },
  {
    path: '/photographer',
    name: 'photographer',
    // route level code-splitting
    // this generates a separate chunk (photographer.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "photographer" */ '../views/photographer.vue')
  },
  {
    path: '/service',
    name: 'service',
    // route level code-splitting
    // this generates a separate chunk (photographer.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "service" */ '../views/service.vue')
  },
]

const router = new VueRouter({
  routes
})
// 全局前置守卫:每次路由切换时，都会调用beforeEach
// to：要跳转到哪 from：当前导航要离开的路由信息 next是否继续跳转路由 如果要就调用next()
router.beforeEach((to, from, next) => {
  // 获取到前面打过标签的元素，将其scrollTop属性设置为0
  document.documentElement.scrollTop = 0
  // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
  document.body.scrollTop = 0
  // 调用继续路由跳转
  next()
})

export default router
