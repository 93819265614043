<template>
    <div>
        <div class="calendarList">
            <!-- <div class="calendarText">档期</div> -->
            <div class="calendarItem" v-for="(item, index) in calendatList7" :key="index">
                <div class="subscribeTag" v-if="item.isSubscribe">已预约</div>
                <div>{{ item.dateNumber }}</div>
                <div class="calendarDateName">{{ item.dateName }}</div>
            </div>
            <div class="calendarItem calendarMore" @click="queryCalendarHandle">更多</div>
        </div>
        <el-dialog title="档期查询" append-to-body top="10vh" :visible.sync="dialogTableVisible">
            <div v-if="dialogTableVisible">
                <el-calendar v-model="calendarvalue" v-loading="calendarLoading">
                    <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                    <template
                        slot="dateCell"
                        slot-scope="{data}">
                        <div v-html="isSelected(data)">
                        </div>
                        <!-- <p :class="data.isSelected ? 'is-selected' : ''">
                        {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '✔️已预约' : ''}} -->
                    </template>
                </el-calendar>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {queryCalendarByUserId} from "@/requestService/indexPage.js"
export default {
    props: {
        cUserId: {
            type: String,
            default: '',
        }
    },
    watch: {
        calendarvalue(val) {
            let year = this.$moment(val).year();
            let month = this.$moment(val).month() + 1;
            console.log(year, month, 'val')
            if(year != this.year || month != this.month) {
                this.year = year;
                this.month = month;
                this.queryCalendarHandle();
            }
        }
    },
    data() {
        return {
            calendarLoading: false,
            calendarvalue: new Date(),
            dialogTableVisible: false,
            calendatList7: [],
            year: this.$moment(new Date()).year(),
            month: this.$moment(new Date()).month() + 1,
            selectedDate: [],
        }
    },
    methods: {
        isSelected(event) {
            let res = this.selectedDate.includes(event.day);
            if(res) {
                return event.day.split('-').slice(1).join('-') + '</br>✔️</br>已预约'
            }else {
                return event.day.split('-').slice(1).join('-')
            }
        },
        async queryCalendarHandle() {
            this.dialogTableVisible = true;
            this.calendarLoading = true;
            let params = {
                year: this.year,
                month: this.month,
                cUserId: this.cUserId
            };
            let res = await queryCalendarByUserId(params);
            console.log(res, '查询当月的预约')
            this.calendarLoading = false;
            if(res.code == 200) {
                let selectedDate = res.data.map(_=> _.selectDate.split(' ')[0])
                this.$nextTick(()=> {
                    this.selectedDate = selectedDate;
                })
                console.log(selectedDate, 'selectedDate')
            }
        },
        async querySeventDayCalendar(userId) {
            this.calendatList7 = this.calendatList7.map(_=> {
                _.isSubscribe = false;
                return _;
            });
            let params = {
                isSeventDay: true,
                isClient: true,
                cUserId: userId? userId:this.cUserId
            };
            console.log(params, 'params');
            let res = await queryCalendarByUserId(params);
            if(res.code == 200) {
                let list = res.data.map(_=> {
                    _.selectDate = _.selectDate.split(' ')[0];
                    return _
                });
                let setList = this.calendatList7.map(_=> {
                    for(let i=0; i<list.length; i++) {
                        let selectItem = list[i];
                        if(selectItem.selectDate == _.dateAll) {
                            _.isSubscribe = true;
                        }
                    }
                    return _;
                });
                this.$nextTick(()=> {
                    this.calendatList7 = setList;
                })
                console.log(setList, 'calendar7')
            }
        },
        // 页面初始化加载未来一周内的数据
        nearlyWeek(numEvent) {
            let time = new Date()     // 
            let date = new Date(time.setDate(time.getDate() + numEvent)).getDate()  //
            date = date< 10? `0${date}`: date;
            let year = time.getFullYear()  //
            let month = (time.getMonth() + 1) < 10? `0${time.getMonth() + 1}`: time.getMonth() + 1  // 获取月份
            let day = time.getDay()   //  获取星期
            switch (day) {             //  格式化
                case 0: day = "周日"
                break
                case 1: day = "周一"
                break
                case 2: day = "周二"
                break
                case 3: day = "周三"
                break
                case 4: day = "周四"
                break
                case 5: day = "周五"
                break
                case 6: day = "周六"
                break
            };
            var obj = {
                // date: date,
                dateNumber: month + '-' + date,
                // month: month,
                dateName: day,
                dateAll: year+'-'+month + '-' + date,
            };
            return obj;
        },
    },
    created() {
        this.calendatList7 = []
        for (let i = 0; i < 7; i++) {
            this.calendatList7.push(this.nearlyWeek(i))
        };
        console.log(this.calendatList7, 'calendatList7');
        this.querySeventDayCalendar();
    },
}
</script>
<style>
.el-dialog__title {
    font-weight: bold;
}
</style>
<style scoped>
.calendarList {
  display: flex;
}
.calendarItem {
  font-size: 12px;
  width: 60px;
  height: 60px;
  background-image: linear-gradient(0deg, #fff, #f3f5f8);
  border: 2px solid #fff;
  box-shadow: 4px 4px 10px 0 rgba(55, 99, 170, .1);
  margin-right: 14px;
  text-align: center;
  padding: 11px 0;
  box-sizing: border-box;
  position: relative;
}
.subscribeTag {
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 28px;
  position: absolute;
  bottom: -30px;
  background-color: #212121;
  color: #fff;
  border-radius: 0 0 4px 4px;
}
.calendarItem:last-child {
  margin-right: 0;
}
</style>