<template>
  <div class="home">
    <div class="bannerRoot">
      <el-carousel height="400px" class="banner_Web">
        <el-carousel-item v-for="item in bannerList" :key="item.swiperId">
          <img :src="item.swiprerUrl" alt="" class="swiprerUrl">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="web_Father">
      <div class="web_Root_Father">
        <div class="fsbold">摄影师</div>
        <div class="fsnormal">PHOTOGRAPHER</div>
      </div>
      <div class="web_Root">
        <div class="web_scroll_list" ref="wrapper">
          <!-- <div class="left-arrow" @click="scrollLeftHandle">
            <i class="el-icon-arrow-left"></i>
          </div> -->
          <div class="scroll-item" v-for="(item) in authorList" :key="item.userId" >
            <div class="userBox" @click="goAuthor(item)">
              <div class="userNickName">{{ item.userNickName }}</div>
              <div class="userAvatar">
                <img :src="item.userAvatar" alt="" class="userAvatarIcon">
              </div>
            </div>
          </div>
          <!-- <div class="right-arrow" @click="scrollrightHandle">
            <i class="el-icon-arrow-right"></i>
          </div> -->
        </div>
      </div>
      <div class="sampleRoot">
        <div class="sampleReels">
          <div class="sampleReelsHeader">
            <!-- <div class="fsbold">THE ONE 艺家摄影空间作品集</div>
            <div class="fsnormal">PHOTOGRAPHY SPACE SAMPLE REELS</div> -->
          </div>
        </div>
        <div class="mt40">
          <el-tabs v-model="activeName" @tab-click="handleClick" >
            <el-tab-pane :label="item.label" :name="item.code" v-for="item in classifiyList" :key="item.code"></el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="classifySample" v-loading="loading">
        <div class="classifySampleCenter">
          <div v-for="item in sampleList" :key="item.resId" class="sampleContent" @click="linkOtherPage(item.resId)">
            <el-image :src="item.resBannerCoverUrl" fit="cover" class="sampleImage"></el-image>
            <!-- <div class="sampleCover"> -->
              <div class="resDesc">
                <div class="resDesc2">{{ item.resDesc }}</div>
                <div class="detailButton">
                  <div class="userInfo">
                    <el-image lazy :src="item.createPortrait" fit="cover" class="createPortrait"></el-image>
                    <div class="">{{ item.createUserName }}</div>
                  </div>
                  <div class="detailFlex">
                    <div>查看详情</div>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <!-- <div class="userInfo">
                <div class="">{{ item.createUserName }}</div>
              </div> -->
            <!-- </div> -->
          </div>
        </div>
        <div class="classifySampleCenter classifyMtPt">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="params.pageNo"
            :page-size="params.pageSize"
            layout="prev, pager, next, jumper, total"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <div class="ourService">
        <div class="ourServiceCenter">
          <div class="sampleReelsHeader">
            <div class="fsbold">THE ONE 服务套系</div>
            <div class="fsnormal">OUR SERVICES</div>
          </div>
          <div class="serviceSample">
            <div class="serviceSampleItem" v-for="(item) in stackList" :key="item.tacksId" @click="goServicePage(item.tacksId)">
              <div class="servicesImageCover">
                <div class="serviceFilter"></div>
                <el-image lazy :src="item.tacksImage" fit="cover" class="serviceSampleImage"></el-image>
              </div>
              <div class="tacksName">{{ item.tacksName }}</div>
              <div class="priceFlex">
                <div class="price">￥ {{ item.price }}</div>
                <div class="prepay">定金{{ item.prepay }}</div>
              </div>
              <div class="tagFlex">
                <div v-for="_this in stackTags(item.serviceList)" :key="_this" class="tag">
                  {{ _this }}
                </div>
              </div>
              <div class="tacksToolFlex">
                <div class="tools1">
                  <i class="el-icon-time"></i>
                  时长({{ item.durationName }})
                </div>
                <div class="tools1">
                  <i class="el-icon-camera"></i>
                  拍摄({{ item.shootName }})
                </div>
                <div class="tools1">
                  <i class="el-icon-picture-outline"></i>
                  精修({{ item.truingName }})
                </div>
                <div class="tools2">
                  <i class="el-icon-cold-drink"></i>
                  服装({{ item.clothing }})
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import {
  homeBannerList,
  qeuryCameraAuthorList,
  queryWorkClassifyList,
  qeuryAchievementList,
  queryStacksManage
} from '@/requestService/indexPage.js'
import Bus from '@/bus/index.js' 
export default {
  data() {
    return{
      loading: false,
      activeName: null,
      bannerList: [{swiprerUrl: require('./../assets/image/banner.png'), swiperId: 1},{swiprerUrl: require('./../assets/image/banner1.png'), swiperId: 2},{swiprerUrl: require('./../assets/image/banner.png'), swiperId: 3},{swiprerUrl: require('./../assets/image/banner1.png'), swiperId: 4}],
      authorList: [],
      classifiyList: [],
      timer: null,
      sampleList: [],
      params: {
        webSite: true,
        pageNo: 1,
        pageSize: 12
      },
      total: 0,
      stackList: [],
    }
  },
  methods: {
    goServicePage(e) {
      this.$router.push({
        path: "/service",
        query: {
          tacksId: e
        }
      })
    },
    goAuthor(e) {
      this.$router.push({
        path: '/photographer',
        query: {
          authorId: e.userId
        }
      })
    },
    linkOtherPage(e) {
      console.log(e, '???')
      this.$router.push({
        path: '/achievement',
        query: {
          resId: e
        }
      })
    },
    stackTags(e) {
      return e.split(",");
    },
    // 查询套系
    async queryStacksManage() {
      let res = await queryStacksManage();
      console.log(res, 'queryStacksManage');
      if(res.code == 200) {
        this.stackList = res.data;
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.params.pageNo = val;
      this.querySample();
      console.log(`当前页: ${val}`);
    },
    // 点击分类
    handleClick(tab, event) {
      console.log(tab, event);
      let workClassifyCode = tab.name;
      if(workClassifyCode) {
        this.params = {
          webSite: true,
          pageNo: 1,
          pageSize: 12,
          workClassifyCode
        };
      }else {
        this.params = {
          webSite: true,
          pageNo: 1,
          pageSize: 12,
        }
      }
      this.querySample();
    },
    // 查询分类列表
    async initClassifiy() {
      let res = await queryWorkClassifyList();
      console.log(res, 'res');
      if(res.code == 200) {
        this.classifiyList = [{code: null, label: "全部"}, ...res.data];
      }
    },
    scrollLeftHandle() {
      // 保存滚动盒子左侧已滚动的距离
      let scrollLeft = this.$refs.wrapper.scrollLeft;
      let num = 0
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        // 已经滚动距离+可见宽度
        // left+clientWidth>=scrollWidth：滚动到最右侧
        // num>=300一次性滚动距离
        if (!scrollLeft || num >= 300) {
          clearInterval(this.timer)
          return
        }
        // 给滚动盒子元素赋值向左滚动距离
        this.$refs.wrapper.scrollLeft = scrollLeft -= 26
        // 保存向左滚动距离（方便判断一次性滚动多少距离）
        num += 30
      }, 20)
    },
    scrollrightHandle() {
      // 保存滚动盒子左侧已滚动的距离
      let scrollLeft = this.$refs.wrapper.scrollLeft;
      // 保存元素的整体宽度
      let scrollWidth = this.$refs.wrapper.scrollWidth
      // 保存元素的可见宽度
      let clientWidth = this.$refs.wrapper.clientWidth;
      let num = 0
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        // 已经滚动距离+可见宽度
        // left+clientWidth>=scrollWidth：滚动到最右侧
        // num>=300一次性滚动距离
        if (scrollLeft + clientWidth >= scrollWidth || num >= 300) {
          clearInterval(this.timer)
          return
        }
        // 给滚动盒子元素赋值向左滚动距离
        this.$refs.wrapper.scrollLeft = scrollLeft += 26;
        // 保存向左滚动距离（方便判断一次性滚动多少距离）
        num += 30
      }, 20)
    },
    async initBanner() {
      let res = await homeBannerList();
      console.log(res, 'res????');
      if(res.code == 200) {
        this.bannerList = res.data
      }
    },
    // 查询摄影师列表
    async initauthorList() {
      let res = await qeuryCameraAuthorList();
      console.log(res, 'authorList');
      if(res.code == 200) {
        this.authorList = res.data
      }
    },
    async querySample(workClassifyCode) {
      this.loading = true;
      let params = {};
      if(workClassifyCode) {
        params = Object.assign({workClassifyCode}, this.params)
      }else {
        params = Object.assign(this.params)
      }
      let res = await qeuryAchievementList(params);
      this.loading = false;
      console.log(res, 'res????');
      if(res.code == 200) {
        this.sampleList = res.data.data;
        this.total = res.data.total
      }
    },
    bannerSplit3(url) {
      let list = url.split(",");
      let listMap = list.map(_=> "http://cos.weeddingstudio.com.cn/achievements/" + _)
      return listMap.slice(0, 3)
    },
  },
  async created() {
    // this.initBanner();
    await this.initauthorList();
    await this.initClassifiy();
    await this.querySample();
    await this.queryStacksManage();
  },
  mounted() {
    
  },
}
</script>
<style>
.web_Root_Father {
  width: 1200px;
  text-align: center;
  margin: 30px auto;
}
.hotlineTitle {
  margin-top: 14px;
  font-size: 12px;
}
.wrapConcatFlex {
  display: flex;
  margin-top: 14px;
}
.viewBox {
  display: inline-block;
  vertical-align: top;
  width: 100px;
  padding: 2px;
  height: 100px;
  background-image: linear-gradient(0deg, #fff 0%, #f3f5f8 100%);
  border: 4px solid #fff;
  box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1), -8px -8px 20px 0 #fff;
  border-radius: 4px;
  margin-right: 20px;
}

.tacksToolFlex {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px;
  box-sizing: border-box;
  font-size: 14px;
  justify-content: space-between;
}
.tools1 {
  width: 32%;
  overflow: hidden;
  text-overflow: ellipsis;		
	display: -webkit-box;			
	-webkit-line-clamp: 1;			
	line-clamp: 1;					
	-webkit-box-orient: vertical;	
}
.tools2 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;		
	display: -webkit-box;			
	-webkit-line-clamp: 1;			
	line-clamp: 1;					
	-webkit-box-orient: vertical;
  margin-top: 10px;
}
.tagFlex {
  padding: 10px 15px;
  box-sizing: border-box;
  display: flex;
}
.tag {
  margin-right: 10px;
  color: #5f7292;
  border: 1px solid rgba(75, 91, 118, .5);
  border-radius: 2px;
  font-size: 12px;
  padding: 0 8px;
  line-height: 20px;
}
.serviceSample {
  width: 1200px;
  margin: 40px auto;
  display: flex;
}
.tacksName {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  padding: 10px 15px;
  box-sizing: border-box;
}
.priceFlex {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  box-sizing: border-box;
}
.price {
  font-size: 18px;
  font-weight: 400;
  color: #f77600;
}
.prepay {
  color: #ccc;
  margin-left: 20px;
  font-weight: 400;
  font-size: 14px;
}
.serviceSampleItem {
  width: 32%;
  background-image: linear-gradient(180deg, #f3f5f8, #fff);
  border: 2px solid #fff;
  box-shadow: 8px 8px 20px rgba(55, 99, 170, .1), -8px -8px 20px #fff;
  border-radius: 6px;
  margin-right: 20px;
  cursor: pointer;
}
.serviceSampleItem:nth-child(3n) {
  margin-right: 0;
}
.serviceSampleItem:hover {
  box-shadow: 8px 8px 20px rgba(55, 99, 170, .2), -8px -8px 20px #fff;
}
.servicesImageCover {
  position: relative;
}
.serviceFilter {
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(1turn, rgba(251, 251, 253, 1), hsla(0, 0%, 100%, 0));
  z-index: 99;
}
.serviceSampleImage {
  width: 100%;
  height: 200px;
  border-radius: 6px 6px 0 0;

}
.ourService {
  width: 100%;
  height: 500px;
  background-color: #fff;
}
.ourServiceCenter {
  width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 60px;
  box-sizing: border-box;
}
.banner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.userInfo {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #212121;
}
.createPortrait {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 10px;
}
.userAvatar {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  overflow: hidden;
}
.sampleContent {
  width: 22%;
  height: 270px;
  transition: box-shadow .3s ease-in-out;
  background-image: linear-gradient(180deg, #f3f5f8, #fff);
  border: 2px solid #fff;
  box-shadow: 8px 8px 20px rgba(55, 99, 170, .1), -8px -8px 20px #fff;
  border-radius: 6px;
  margin-right: 30px;
  margin-bottom: 30px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  padding: 4px;
  box-sizing: border-box;
}
.sampleContent:hover {
  box-shadow: 8px 8px 20px rgba(55, 99, 170, .2), -8px -8px 20px #fff;
}
.sampleContent:nth-child(4n) {
  margin-right: 0;
}
.sampleImage {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.sampleCover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .2), rgba(243, 245, 248, 1));
  /* padding: 15px; */
  box-sizing: border-box;
  line-height: 24px;
}
.resDesc {
  background-color: rgba(255, 255, 255, .3);
  backdrop-filter: blur(15px);
  font-size: 12px;
  position: absolute;
  bottom: 0;
  /* margin-bottom: 38px; */
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
}
.detailButton {
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detailFlex {
  display: flex;
  align-items: center;
}
.resDesc2 {
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;		
	display: -webkit-box;			
	-webkit-line-clamp: 2;			
	line-clamp: 2;					
	-webkit-box-orient: vertical;	
}
.el-tabs__header {
  margin: 0 !important
}
.classifySample {
  width: 100%;
  background: #f3f5f8;
  box-shadow: inset 8px 8px 20px 0 rgba(55, 99, 170, .11), inset -8px -8px 20px 0 #fff;
  min-height: 400px;
}
.classifySampleCenter {
  width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.classifyMtPt {
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px !important;
  /* background-color: #E4E7ED; */
  z-index: 1;
}
.bannerRoot {
  width: 100%;
}
.banner_Web {
  /* width: 1200px; */
  margin: auto;
}
.swiprerUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.authorList {
  width: 100%;
  height: 500px;
  background-color: #f3f5f8;
}
.web_Father {
  width: 100%;
  position: relative;
  /* top: -40px; */
  z-index: 9;
  /* background-image: radial-gradient(50% 41%,#f3f5f8 0,#fff 41%); */
  /* background-color: #fff; */
}
.web_Root {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  top: -20px;
  z-index: 99999;
}
.sampleRoot {
  width: 1200px;
  margin: 0 auto;
}
.left-arrow {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
  background-image: linear-gradient(135deg, #dbe2e9, #fff);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .16);
  /* background-image: linear-gradient(0deg,#fff,#f3f5f8);
  box-shadow: 8px 8px 10px 0 rgba(55,99,170,.1); */
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.left-arrow:hover {
  border: 1px solid #333;
  box-shadow: none;
}
.right-arrow {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
  background-image: linear-gradient(135deg, #dbe2e9, #fff);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .16);
  /* background-image: linear-gradient(0deg,#fff,#f3f5f8);
  box-shadow: 8px 8px 10px 0 rgba(55,99,170,.1); */
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.right-arrow:hover {
  border: 1px solid #333;
  box-shadow: none;
}
.web_scroll_list {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* white-space: nowrap;
  overflow-x: scroll; */
}
/* 定义滚动条样式 */
.web_scroll_list::-webkit-scrollbar {
  display: none;
}
.scroll-item {
  box-shadow: 8px 8px 16px 0 rgba(55,99,170,.1);
  width: 23.5%;
  height: 100px;
  margin-bottom: 20px;
  background-image: linear-gradient(0deg,#fff,#f3f5f8);
  border: 2px solid #fff;
  border-radius: 6px;
  display: inline-block;

}
.userBox {
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* justify-content: center; */
}
.userAvatar {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  overflow: hidden;
}
.userAvatarIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.userNickName {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
.scroll-item:last-child {
  margin-right: 0;
}
.sampleReels {
  margin-top: 20px;
}
.sampleReelsHeader {
  font-size: 24px;
  text-align: center;
}
.fsbold {
  font-size: 28px;
  font-weight: 500;
  color: #333;
}
.fsnormal {
  font-size: 14px;
  font-weight: 300;
  color: #3d485d;
  margin-top: 10px;
}
.mt40 {
  margin-top: 40px;
}
</style>
