<template>
  <div id="app">
    <div class="navHeader">
      <div class="navRoot">
        <div class="navLeft" @click="linkHomePage">
          <!-- <img src="@/assets/image/navLogo.jpg" alt="" class="logoIcon"> -->
          <div class="kai_logo">
            <div class="navLogo">THE ONE</div>
            <div class="navName">艺家摄影空间</div>
          </div>
        </div>
        <div class="navSearch">
          <el-date-picker v-model="value1" type="date" placeholder="选择档期"></el-date-picker>
          <div class="searchHandle" @click="searchDateHandle">查询</div>
        </div>
      </div>
    </div>
    <router-view/>
    <div class="webSiteFooter">
      <div class="webSiteFooterCenter">
        <div class="footerLeft">
          <div>
            <div>拍摄有温度的照片，记录平凡生活中的美好。</div>
            <div>每一张照片，都是时光的标本；每一次快门，都是一瞬间的精彩。</div>
            <div>为你停住时光的脚步，为你扑捉霎那间的极致。一路上有我，伴随着你人生旅途，记录美好的点滴。</div>
          </div>
          <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">豫ICP备2024065138号-2</a>
<!--          <div class="beian" @click="linkBeian">-->
<!--            公安备案号 豫IC备2024065138号-2-->
<!--          </div>-->
        </div>
        <div class="right">
          <div class="webSiteFooterTitle">关注或联系我们</div>
          <div class="webSiteFooterText">添加微信号、小程序。</div>
          <div class="wrapConcatFlex">
            <div class="viewBox">
              <img src="@/assets/image/qrcode.jpg" alt="" style="width: 100%; height: 100%;">
            </div>
            <div class="viewBox">
              <img src="@/assets/image/mini.png" alt="" style="width: 100%; height: 100%;">
            </div>
          </div>
          <div class="hotlineTitle">
            咨询热线 <span style="font-size: 14px;">153-7382-8882</span>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      append-to-body
      :visible.sync="drawer"
      direction="ttb"
      :with-header="false"
      :before-close="handleClose">
        <div class="fullPage" v-loading="cameraLoading">
          <div class="closeDrawerIcon" @click="handleClose">
            <i class="el-icon-close"></i>
          </div>
          <div class="fullPageCenter">
            <div class="tl-tabs tl-tabs--vertical">
              <div class="tl-tabs__bar">
                <div :class="activeUserId==item.userId?'tl-tabs__item is-active': 'tl-tabs__item'" v-for="(item) in cameraManList" :key="item.userId">
                  <div :class="activeUserId==item.userId?'tl-tabs__item-cont is-active': 'tl-tabs__item-cont'" @click="selectUserHandle(item)">
                    <div class="">
                      <el-image lazy :src="item.userAvatar" fit="cover" class="appuserAvatar"></el-image>
                    </div>
                    <div class="appml20">
                      <div class="appUserNickName">{{ item.userNickName }}</div>
                      <div class="appUserPrice">￥{{ item.userPrice }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tl-tabs__cont">
                <calendar-el :cUserId="activeUserId" ref="calendarEl"></calendar-el>
                <div class="appSampleClassify">
                  <el-tabs v-model="activeName" @tab-click="handleClick" >
                    <el-tab-pane :label="item.label" :name="item.code" v-for="item in classifiyList" :key="item.code"></el-tab-pane>
                  </el-tabs>
                  <div v-if="sapmleReelList.length">
                    <div class="appSampleContent" v-loading="loading">
                      <div v-for="item in sapmleReelList" :key="item.resId" class="appsampleContent1" @click="linkOtherPage(item.resId)">
                        <el-image lazy :src="item.resBannerCoverUrl" fit="cover" class="appSampleImage"></el-image>
                          <div class="appResDesc">
                            <div class="resDesc2">{{ item.resDesc }}</div>
                            <div class="appDetailButton">
                              <div>查看详情</div>
                              <i class="el-icon-arrow-right"></i>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="" v-else>
                    <div class="appNodata">暂无数据</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </el-drawer>
  </div>
</template>
<script>
// import Bus from '@/bus/index.js' 
// import { Select } from 'element-ui';
import calendarEl from "@/components/calendarEl.vue"
import {querySubscribeorderByTime, qeuryCameraAuthorList, queryWorkClassifyList, qeurySampleReel} from '@/requestService/indexPage.js';
export default {
  components: {
    calendarEl
  },
  data() {
    return{
      cameraLoading: false,
      appSampleLoading: false,
      sapmleReelList: [],
      classifiyList: [],
      value1: "",
      drawer: false,
      cameraManList: [],
      activeUserId: "",
      activeName: null,
      loading: false,
    }
  },
  methods: {
    linkOtherPage(e) {
      console.log(e, '???')
      if(this.$route.fullPath == '/achievement') {
        this.drawer = false;
        // 获取当前URL
        var url = window.location.href;
        // 修改query参数
        var newQuery = "resId=" + e;
        // 拼接新的URL
        var newUrl = url.split('?')[0] + '?' + newQuery ;
        // 修改地址栏URL
        history.pushState(null, null, newUrl);
        return;
      }
      this.drawer = false;
      this.$router.push({
        path: '/achievement',
        query: {
          resId: e
        }
      })
    },
    handleClick(e) {
      console.log(e, 'handleClick');
      if(this.classifyName == e.name) return;
      this.qeurySampleReel(e.name);
    },
    async qeurySampleReel(e) {
      this.loading = true;
      let params = {};
      if(e) {
        params = {
          cUserId: this.activeUserId,
          workClassifyCode: e
        }
        this.classifyName = e;
      }else {
        params = {
          cUserId: this.activeUserId
        }
        this.classifyName = null;
      }
      let res = await qeurySampleReel(params);
      this.loading = false;
      if(res.code == 200) {
        if(!e) {
          this.total = res.data.length
        }
        this.sapmleReelList = res.data;
      }
    },
    // 查询分类列表
    async initClassifiy() {
      let res = await queryWorkClassifyList();
      console.log(res, 'res');
      if(res.code == 200) {
        this.classifiyList = [{code: null, label: "全部"}, ...res.data];
      }
    },
    selectUserHandle(e) {
      this.activeUserId = e.userId;
      this.qeurySampleReel();
      this.$nextTick(()=> {
        this.activeName = null;
      })
      this.$refs['calendarEl'].querySeventDayCalendar(e.userId)
    },
    handleClose() {
      this.drawer = false;
    },
    async initSubscribeorder() {
      if(this.value1) {
        this.cameraLoading = true;
        let res = await querySubscribeorderByTime({selectDate: this.$moment(this.value1).format("YYYY-MM-DD")});
        console.log(res, 'querySubscribeorderByTime');
        if(res.code == 200) {
          let cameraRes = await qeuryCameraAuthorList();
          if(cameraRes.code == 200) {
            if(res.data.length) {
              let cameraManList = cameraRes.data.filter(item=>!res.data.some(ele=>ele.cUserId===item.userId));
              if(cameraManList.length) {
                this.cameraManList = cameraManList;
                this.activeUserId = this.cameraManList[0].userId;
                this.$refs['calendarEl'].querySeventDayCalendar(this.cameraManList[0].userId)
                this.qeurySampleReel();
                console.log(cameraManList, 'cameraManList');
              }else {
                this.cameraManList = [];
              }
            }else {
              this.cameraManList = cameraRes.data;
              this.activeUserId = cameraRes.data[0].userId;
              this.activeUserId = cameraRes.data[0].userId;
              this.qeurySampleReel();
            }
          }
          this.cameraLoading = false;
        }
      }
    },
    searchDateHandle() {
      if(this.value1) {
        console.log(this.value1, 'value1');
        this.drawer = true;
        this.initSubscribeorder();
      }else {
        this.$message({
          message: '警告, 请选择档期',
          type: 'warning'
        });
      };
      // Bus.$emit('searchCameramanHandle', this.value1)
    },
    linkHomePage() {
      if(this.$route.fullPath == '/'){
        return
      }
      this.$router.push("/")
    },
    linkBeian() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
  },
  created() {
    document.title = "THE ONE 艺家摄影空间";
    this.initClassifiy();
  }
}
</script>

<style>
.appNodata {
  text-align: center;
  margin-top: 100px;
  height: 75vh;
  font-size: 16px;
}
.appDetailButton {
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}
.appResDesc {
  background-color: rgba(255, 255, 255, .3);
  backdrop-filter: blur(15px);
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  /* margin-bottom: 38px; */
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
}
.appsampleContent1 {
  width: 30%;
  height: 270px;
  transition: box-shadow .3s ease-in-out;
  background-image: linear-gradient(180deg, #f3f5f8, #fff);
  border: 2px solid #fff;
  box-shadow: 8px 8px 20px rgba(55, 99, 170, .1), -8px -8px 20px #fff;
  border-radius: 6px;
  margin-right: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  padding: 4px;
  box-sizing: border-box;
}
.appsampleContent1:hover {
  box-shadow: 8px 8px 20px rgba(55, 99, 170, .2), -8px -8px 20px #fff;
}
.appsampleContent1:nth-child(3n) {
  margin-right: 0;
}
.appSampleImage {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.appSampleContent {
  width: 100%;
  height: 75vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.appSampleClassify {
  margin-top: 40px;
}
.appml20 {
  margin-left: 20px;
}
.appUserPrice {
  margin-top: 8px;
  color: #f77600;
}
.appUserNickName {
  font-size: 16px;
}
.appuserAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-drawer {
  height: 100vh !important;
}

.fullPage {
  height: 100vh;
  position: relative;
  padding: 15px;
  box-sizing: border-box;
  background: #f3f5f8;
  padding-top: 25px;
}
.tl-tabs--vertical {
  display: flex;
}
.tl-tabs {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
.tl-tabs__bar {
  position: relative;
  margin-right: -2px;
  padding: 0;
  width: 200px;
  box-sizing: border-box;
  white-space: normal;
}
.tl-tabs__item-cont {
  padding: 12px;
  background: #f3f5f8;
  box-shadow: inset 8px 8px 20px 0 rgba(55, 99, 170, .11), inset -8px -8px 20px 0 #fff;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: #495770;
  text-align: center;
  border: 2px solid transparent;
  transition: background .3s linear;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.tl-tabs__item-cont::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: #0052d9;
    transition: height .15s ease-in-out, width .15s ease-in-out .15s;
}
.tl-tabs__cont {
  padding: 15px;
  background-image: linear-gradient(0deg, #f4f6f9, #f3f5f8);
  border: 2px solid #fff;
  box-shadow: inset 8px 8px 20px 0 rgba(55, 99, 170, .0), inset -8px -8px 20px 0 #fff;
  /* box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1), -8px -8px 20px 0 #fff; */
  flex: 1;
  width: 1px;
  position: relative;
  z-index: 9;
  height: 90vh;
}
.tl-tabs__item.is-active {
  padding-right: 0;
}
.tl-tabs--vertical .tl-tabs__item.is-active .tl-tabs__item-cont  {
  padding-right: 30px;
  background-image: linear-gradient(0deg, #f4f6f9, #f3f5f8);
  border: 2px solid #fff;
  border-right: none;
  box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1), -8px -8px 20px 0 #fff !important;
  font-weight: 400;
  color: #495770;
}
.fullPageCenter {
  width: 1200px;
  margin: 0 auto;
}
.tl-tabs__list {
  position: relative;
  display: inline-block;
  min-width: 100%;
  font-size: 0;
  list-style: none;
  text-align: center;
  vertical-align: top;
}
.tl-tabs__item {
  display: block;
  min-width: 0;
  margin-bottom: 20px;
  padding-right: 18px;
}
.closeDrawerIcon {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
* {
  margin: 0;
  padding: 0;
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.navHeader {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #E0E3E6;
  padding: 10px 0;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  color: #181818;
  z-index: 999;
}
.navRoot {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.logoIcon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.navSearch {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchHandle {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  background-color: #333333;
  border-radius: 4px;
  margin-left: 6px;
  color: #fff;
  font-size: 16px;
}
.searchHandle:hover {
  background-color: #4D4D4D;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important;
}
.navLogo {
  font-size: 26px;
  font-weight: bold;
}
.kai_logo {
  text-align: center;
}
.navName {
  font-size: 12px;
  margin-top: 4px;
}
.webSiteFooterTitle {
  font-weight: normal;
  font-size: 14px;
  color: #000;
  line-height: 22px;
}
.webSiteFooterText {
  font-size: 12px;
  color: #495770;
  line-height: 24px;
  margin-top: 16px;
}
.webSiteFooter {
  padding: 60px 0;
  box-sizing: border-box;
  width: 100%;
  /* height: ; */
  background: rgb(245, 247, 250);
  height: 300px;
  position: relative;
  top: 110px;
}
.beian {
  margin-top: 124px;
  cursor: pointer;
  moz-user-select: all;
  -moz-user-select: all;
  -o-user-select: all;
  -khtml-user-select: all;
  -webkit-user-select: all;
  -ms-user-select: all;
  user-select: all;
}
.beian:hover {
  color: blue;
}
.webSiteFooterCenter {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footerLeft {
  font-size: 14px;
  line-height: 24px;
}
</style>
