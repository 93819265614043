import instance from "./interceptor";

// 获取轮播图
export const homeBannerList = (params)=> instance.post('/dapt/miniProgram/queryHomeIndexBannerList', params);

// 查询摄影师
export const qeuryCameraAuthorList = (params)=> instance.post('/dapt/miniProgram/qeuryCameraAuthorList', params);

// 查询作品分类
export const queryWorkClassifyList = (params)=> instance.post('/dapt/miniProgram/queryWorkClassifyList', params);

// 根据分类id查询作品
export const qeuryAchievementList = (params)=> instance.post('/dapt/miniProgram/qeuryAchievementList', params);

// 查询套系
export const queryStacksManage = (params)=> instance.post('/dapt/miniProgram/queryStacksManage', params);

// 查询作品详情
export const qeuryAchivements = (params)=> instance.post('/dapt/miniProgram/queryAchievementsByResId', params);

// 查询作者
export const queryAuthorById = (params)=> instance.post('/dapt/miniProgram/queryAuthorById', params);

// 查询作者下的作品集合
export const qeurySampleReel = (params)=> instance.post('/dapt/miniProgram/qeurySampleReelByAuthorIdWork', params);

// 查询档期 
export const queryCalendarByUserId = (params)=> instance.post('/dapt/miniProgram/queryCalendarByUserId', params);

// 根据日期查询已经预约的摄影师 
export const querySubscribeorderByTime = (params)=> instance.post('/dapt/miniProgram/querySubscribeorderByTime', params);

// 查询套系详情
export const queryStacksById = (params)=> instance.post("/dapt/miniProgram/queryStacksById", params);