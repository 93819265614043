
import axios from "axios"

// 创建实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 1000 * 5
})
instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(
    config => {
    //   const token = getToken()
    //   token && (config.headers.Authorization = token);
      return config
    },
    error => {
      
      return Promise.error(error)
    }
)

instance.interceptors.response.use(res => {
      return res.data
    }, err => {
      return Promise.reject(err)
    }
)    
export default instance;
  